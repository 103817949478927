import React from "react";
import "../../App.css";
import SignUpForm from '../SignUpForm'

function SignUp() {
  return (
    <>
      <div className="fullscreen-background">
        <SignUpForm />
      </div>
    </>
  );
}

export default SignUp;
