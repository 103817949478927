import React from "react";
import "../../App.css";
import HeroMain from "../HeroMain";

function Home() {
  return (
    <>
      <div className="fullscreen-background">
        <HeroMain />
      </div>
    </>
  );
}

export default Home;
